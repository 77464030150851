import { configureStore } from '@reduxjs/toolkit';
import searchReducer from '../services/searchSlice';
import authReducer from '../services/authSlice';
import listingsReducer from '../services/listingSlice';
import atsReducer from '../services/atsSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    search: searchReducer,
    listings: listingsReducer,
    ats: atsReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
});

export default store; 