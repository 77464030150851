import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMergeLink } from '@mergeapi/react-merge-link';
import { Button, CircularProgress, Alert, styled, Box } from '@mui/material';
import { Link as LinkIcon, Sync as SyncIcon } from '@mui/icons-material';
import { createLinkToken, clearError, exchangeToken, syncAtsData } from '../../services/atsSlice';

// Match the NavItem styling from DashboardLayout
const StyledNavItem = styled(Button)(({ theme, active }) => ({
  color: 'white',
  justifyContent: 'flex-start',
  padding: theme.spacing(1.5, 2),
  borderRadius: '8px',
  textTransform: 'none',
  fontSize: '16px',
  width: '100%',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const ATSIntegration = () => {
  const dispatch = useDispatch();
  const { linkToken, loading, error, integrated, syncing } = useSelector((state) => state.ats);

  const onSuccess = useCallback((public_token) => {
    dispatch(exchangeToken(public_token));
  }, [dispatch]);

  const { open, isReady } = useMergeLink({
    linkToken,
    onSuccess,
  });

  const handleSync = async () => {
    await dispatch(syncAtsData());
  };

  const handleClick = async () => {
    console.log('ATSIntegration clicked');
    dispatch(clearError());
    console.log('error cleared');

    if (!linkToken) {
      await dispatch(createLinkToken());
    }
    if (isReady) {
      open();
    }
  };

  if (error) {
    return (
      <Alert 
        severity="error" 
        sx={{ mb: 2 }}
        onClose={() => dispatch(clearError())}
      >
        {error}
      </Alert>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <StyledNavItem
        onClick={handleClick}
        disabled={loading}
        sx={{ ml: 1 }}
        startIcon={loading ? <CircularProgress size={20} color="inherit" sx={{ mr: 1 }} /> : <LinkIcon sx={{ mr: 1 }} />}
      >
        {integrated ? 'ATS Connected' : (loading ? 'Connecting...' : 'Connect ATS')}
      </StyledNavItem>

      {true && (
        <StyledNavItem
          onClick={handleSync}
          disabled={syncing}
          sx={{ ml: 1 }}
          startIcon={syncing ? <CircularProgress size={20} color="inherit" /> : <SyncIcon />}
        >
          {syncing ? 'Syncing...' : 'Sync ATS Data'}
        </StyledNavItem>
      )}

      {error && (
        <Alert 
          severity="error" 
          onClose={() => dispatch(clearError())}
        >
          {error}
        </Alert>
      )}
    </Box>
  );
};

export default ATSIntegration;