// File: src/components/Home/Home.js

import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Button,
  Container
} from '@mui/material';

import { Link } from 'react-router-dom';

// Icons and images
import SearchIcon from '@mui/icons-material/Search';
import SpeedIcon from '@mui/icons-material/Speed';
import BusinessIcon from '@mui/icons-material/Business';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

import logo from '../../assets/logo.png';
import rishi from '../../assets/rishi.jpeg';
import jay from '../../assets/jay.jpeg';
import soham from '../../assets/soham.jpeg';

import profile1 from '../../assets/profile1.jpg';
import profile2 from '../../assets/profile2.jpg';
import profile3 from '../../assets/profile3.jpg';
import profile4 from '../../assets/profile4.jpg';
import profile5 from '../../assets/profile5.jpg';
import profile6 from '../../assets/profile6.jpg';
import profile7 from '../../assets/profile7.jpg';
import profile8 from '../../assets/profile8.jpg';

/* 
  HOME PAGE SECTIONS:
  1) Hero Section
  2) Build Amazing Teams
  3) Hiring Process
  4) Meet the Team
  5) Start Hiring
  6) Footer
*/

const Home = () => {
  return (
    <>
      {/* 1) HERO SECTION */}
      <Box sx={{ backgroundColor: '#fff', px: { xs: 2, md: 4 }, py: 4 }}>
        <Box
          id="hero"
          sx={{
            background: 'linear-gradient(135deg, #1a47b8 0%, #0a2351 100%)',
            minHeight: '600px',
            padding: { xs: '60px 20px', md: '80px 40px' },
            textAlign: 'center',
            color: '#fff',
            position: 'relative',
            overflow: 'hidden',
            borderRadius: '24px',
            maxWidth: '1400px',
            margin: '0 auto',
          }}
        >
          <Container maxWidth="lg">
            <Typography
              variant="h1"
              sx={{
                fontSize: { xs: '32px', md: '48px' },
                fontWeight: 700,
                mb: 2,
                maxWidth: '800px',
                mx: 'auto',
              }}
            >
              Screen Applicants 10x Faster with Intratalent AI
            </Typography>

            <Typography
              sx={{
                fontSize: { xs: '16px', md: '20px' },
                mb: 6,
                maxWidth: '800px',
                mx: 'auto',
              }}
            >
              Intratalent's AI-powered talent screening instantly identifies your best candidates.
              Get accurate matches in seconds by analyzing resumes, GitHub contributions, and research papers—
              all while keeping your existing ATS.
            </Typography>
            


           {/* Profile Images Layout (dynamically sized & offset to mimic second screenshot) */}
<Box
  sx={{
    position: 'relative',
    width: '100%',
    maxWidth: '1200px',
    height: { xs: '220px', md: '300px' }, // Increase if needed for more vertical space
    mx: 'auto',
    mb: 4,
    mt: 8,
  }}
>
  {[
    { img: profile1, size: { xs: 0, md: 200 }, offsetX: '10%', offsetY: '-80%', showOnMobile: false },
    { img: profile2, size: { xs: 130, md: 130 }, offsetX: '27%', offsetY: { xs: '20%', md: '-20%' }, showOnMobile: true },
    { img: profile4, size: { xs: 0, md: 100 }, offsetX: '37%', offsetY: '-160%', showOnMobile: false },
    { img: profile6, size: { xs: 160, md: 190 }, offsetX: '50%', offsetY: { xs: '-80%', md: '-30%' }, showOnMobile: true },
    { img: profile5, size: { xs: 0, md: 100 }, offsetX: '63%', offsetY: '-160%', showOnMobile: false },
    { img: profile3, size: { xs: 130, md: 130 }, offsetX: '74%', offsetY: { xs: '20%', md: '-20%' }, showOnMobile: true },
    { img: profile7, size: { xs: 0, md: 130 }, offsetX: '89%', offsetY: '-95%', showOnMobile: false },
  ].map((profile, index) => (
    <Box
      key={index}
      sx={{
        position: 'absolute',
        left: profile.offsetX,
        top: '50%',
        transform: typeof profile.offsetY === 'object' 
          ? { 
              xs: `translate(-50%, ${profile.offsetY.xs})`,
              md: `translate(-50%, ${profile.offsetY.md})`
            }
          : `translate(-50%, ${profile.offsetY})`,
        display: { 
          xs: profile.showOnMobile ? 'block' : 'none',
          md: 'block' 
        }
      }}
    >
      <Box
        component="img"
        src={profile.img}
        alt={`profile-${index}`}
        sx={{
          width: { xs: `${profile.size.xs}px`, md: `${profile.size.md}px` },
          height: { xs: `${profile.size.xs}px`, md: `${profile.size.md}px` },
          borderRadius: '50%',
          border: '0px solid white',
          objectFit: 'cover',
          boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'translateY(-3px) scale(1.05)',
          },
        }}
      />
    </Box>
  ))}
</Box>


          </Container>
        </Box>
      </Box>

      {/* 2) BUILD AMAZING TEAMS SECTION */}
      <Container maxWidth="lg">
        <Box id="teams" sx={{ py: 8, px: 3, textAlign: 'center' }}>
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: '28px', md: '40px' },
              fontWeight: 700,
              mb: 2,
            }}
          >
            Transform Your Hiring Process
          </Typography>
          <Typography sx={{ mb: 6, maxWidth: '800px', mx: 'auto' }}>
            Intratalent seamlessly integrates with your current ATS system to deliver
            faster, more accurate candidate screening powered by advanced AI.
          </Typography>

          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} md={4}>
              <FeatureCard
                icon={<SpeedIcon sx={{ fontSize: 40 }} />}
                title="Lightning Fast"
                description="Screen hundreds of resumes in minutes, not days. Get instant insights on every candidate."
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FeatureCard
                icon={<BusinessIcon sx={{ fontSize: 40 }} />}
                title="Keep Your ATS"
                description="No migration needed. Intratalent works alongside your existing systems for seamless integration."
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FeatureCard
                icon={<TrendingUpIcon sx={{ fontSize: 40 }} />}
                title="Smart Matching"
                description="Our AI analyzes resumes, GitHub code, and research papers to find your perfect candidates."
              />
            </Grid>
          </Grid>
        </Box>

        {/* 3) HIRING PROCESS SECTION */}
        <Box id="process" sx={{ py: 8, px: 3 }}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={5}>
              <Typography
                variant="h2"
                sx={{ fontSize: { xs: '28px', md: '40px' }, fontWeight: 700, mb: 3 }}
              >
                Hiring Top Talent Made Easy
              </Typography>
              <Typography>
                Find better candidates faster with Intratalent's AI-powered resume screening.
              </Typography>
            </Grid>
            <Grid item xs={12} md={7}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                {[
                  {
                    number: '01',
                    title: 'Upload Job Description',
                    description: 'Simply paste your job requirements or upload an existing JD.',
                  },
                  {
                    number: '02',
                    title: 'AI-Powered Screening',
                    description: 'Our algorithm instantly analyzes and ranks candidates, explaining why each is a good fit.',
                  },
                  {
                    number: '03',
                    title: 'Review Top Matches',
                    description: 'Get detailed insights on your best candidates and why they match your requirements.',
                  },
                ].map((step, index) => (
                  <Box
                    key={index}
                    sx={{
                      backgroundColor: '#f8f9fa',
                      borderRadius: '8px',
                      padding: 3,
                      display: 'flex',
                      gap: 3,
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#1a47b8',
                        fontWeight: 700,
                        fontSize: '24px',
                      }}
                    >
                      {step.number}
                    </Typography>
                    <Box>
                      <Typography variant="h6" sx={{ mb: 1 }}>
                        {step.title}
                      </Typography>
                      <Typography>{step.description}</Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* 4) MEET THE TEAM SECTION */}
        <Box id="team" sx={{ py: 8, px: 3, textAlign: 'center' }}>
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: '28px', md: '40px' },
              fontWeight: 700,
              mb: 2,
            }}
          >
            Meet the Intratalent Team
          </Typography>
          <Typography sx={{ mb: 6, maxWidth: '800px', mx: 'auto' }}>
            Behind Intratalent AI is a team of innovators, recruiters, and tech enthusiasts
            reshaping the future of hiring with industry expertise and advanced AI.
          </Typography>

          <Grid container spacing={4} justifyContent="center">
            {[
              { name: 'Rishi Sadanandan', img: rishi },
              { name: 'Jay Khemchandani', img: jay },
              { name: 'Soham Vasanawala', img: soham },
            ].map((member, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Box sx={{ textAlign: 'center' }}>
                  <Box
                    component="img"
                    src={member.img}
                    alt={member.name}
                    sx={{
                      width: '150px',
                      height: '150px',
                      borderRadius: '50%',
                      mb: 2,
                      objectFit: 'cover',
                    }}
                  />
                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    {member.name}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* 5) START HIRING SECTION */}
        <Box
          id="start"
          sx={{
            py: 8,
            px: 3,
            textAlign: 'center',
            backgroundColor: '#f8f9fa',
            borderRadius: '20px',
            margin: '24px',
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: '28px', md: '40px' },
              fontWeight: 700,
              mb: 2,
            }}
          >
            Start Screening Smarter Today
          </Typography>
          <Typography sx={{ mb: 4, maxWidth: '700px', mx: 'auto' }}>
            Join forward-thinking companies using AI to find their best candidates faster. 
            Try Intratalent free and see the difference intelligent screening makes.
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
            <Button
              variant="contained"
              component={Link}
              to="/signup"
              sx={{
                backgroundColor: '#1a47b8',
                color: '#fff',
                px: 4,
                py: 1.5,
                borderRadius: '30px',
                '&:hover': { backgroundColor: '#153a94' },
              }}
            >
              Post a Job
            </Button>
            <Button
              variant="outlined"
              component={Link}
              to="/demo"
              sx={{
                borderColor: '#1a47b8',
                color: '#1a47b8',
                px: 4,
                py: 1.5,
                borderRadius: '30px',
                '&:hover': { borderColor: '#153a94', color: '#153a94' },
              }}
            >
              Try Demo
            </Button>
          </Box>
        </Box>
      </Container>

      {/* 6) FOOTER */}
      <Box
        sx={{
          backgroundColor: '#1a47b8',
          color: '#fff',
          py: 6,
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            {/* Logo & Description */}
            <Grid item xs={12} md={4}>
              <Box
                component="img"
                src={logo}
                alt="Intratalent AI"
                sx={{ height: '40px', mb: 2 }}
              />
              <Typography sx={{ mb: 2 }}>
                Intratalent is an AI-powered resume screening solution that helps companies
                find their ideal candidates 10x faster. Our intelligent matching algorithm
                analyzes multiple data points to deliver accurate candidate recommendations
                while seamlessly integrating with your existing ATS.
              </Typography>
            </Grid>

            {/* Quick Links */}
            <Grid item xs={12} md={4}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Quick Links
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                }}
              >
                <a href="#hero" style={{ color: '#fff', textDecoration: 'none' }}>
                  Hero
                </a>
                <a href="#teams" style={{ color: '#fff', textDecoration: 'none' }}>
                  Build Teams
                </a>
                <a href="#process" style={{ color: '#fff', textDecoration: 'none' }}>
                  Process
                </a>
                <a href="#team" style={{ color: '#fff', textDecoration: 'none' }}>
                  Our Team
                </a>
                <a href="#start" style={{ color: '#fff', textDecoration: 'none' }}>
                  Get Started
                </a>
              </Box>
            </Grid>

            {/* Contact Info */}
            <Grid item xs={12} md={4}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Contact Us
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography>contact@intratalent.ai</Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

/* 
  Helper component for features
*/
const FeatureCard = ({ icon, title, description }) => (
  <Box
    sx={{
      textAlign: 'center',
      padding: 3,
      backgroundColor: '#f8f9fa',
      borderRadius: '8px',
      height: '100%',
    }}
  >
    <Box sx={{ color: '#1a47b8', mb: 2 }}>{icon}</Box>
    <Typography variant="h6" sx={{ mb: 2 }}>
      {title}
    </Typography>
    <Typography>{description}</Typography>
  </Box>
);

export default Home;