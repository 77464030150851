import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store/configureStore';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const silentResizeObserverErrors = (e) => {
  if (e?.message?.includes?.('ResizeObserver')) {
    // Ignore ResizeObserver errors
    return;
  }
  // Log all other errors normally
  console.error(e);
};

window.addEventListener('error', silentResizeObserverErrors);
window.addEventListener('unhandledrejection', silentResizeObserverErrors);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
