// File: src/components/Auth/AuthPage.js

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import {
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  CircularProgress,
  InputAdornment,
  IconButton,
  Grid,
  Avatar,
  Container,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { login, signup, clearSignupSuccess } from '../../services/authSlice';
import GoogleAuthButton from './GoogleAuthButton';

import { styled } from '@mui/system';
import '@fontsource/plus-jakarta-sans';
import authBackground from '../../assets/auth_background.jpg'; 
import logo from '../../assets/logo-white.png';

const AuthContainer = styled(Grid)(({ theme }) => ({
  height: '100vh',
  width: '100%',
  fontFamily: '"Plus Jakarta Sans", sans-serif',
}));

const LeftPanel = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${authBackground})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '100%',
  height: '100%',
  [theme.breakpoints.down('md')]: {
    display: 'none', // Hide left panel on smaller screens for a cleaner layout
  },
}));

const Overlay = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const BrandingBox = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  color: '#fff',
}));

const RightPanel = styled(Grid)(({ theme }) => ({
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(4),
}));

const FormWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '400px',
  padding: theme.spacing(4),
  borderRadius: '8px',
  border: '1px solid #eee',
  boxShadow: '0 2px 10px rgba(0,0,0,0.05)',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
  },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(1.5),
  borderRadius: '8px',
  textTransform: 'none',
  fontWeight: 600,
  fontSize: '16px',
  backgroundColor: '#1A365D',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#16305A',
  },
}));

const AuthLinkText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  color: '#666',
}));

const AuthLinkButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  color: '#1A365D',
  fontWeight: 600,
  fontSize: '14px',
  '&:hover': {
    backgroundColor: 'rgba(26, 54, 93, 0.04)',
  },
}));

const AuthPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // State to toggle between login and signup
  const [isSignup, setIsSignup] = useState(false);

  // Add isGoogleSignIn state
  const [isGoogleSignIn, setIsGoogleSignIn] = useState(false);

  // Form data
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  // Form errors
  const [formErrors, setFormErrors] = useState({});
  // Password visibility
  const [showPassword, setShowPassword] = useState(false);

  // Redux store data
  const { loading, error, signupSuccess, signupMessage, token } = useSelector((state) => state.auth);

  // If user is logged in, navigate to dashboard
  useEffect(() => {
    const localToken = localStorage.getItem('token');
    if (token || localToken) {
      navigate('/dashboard');
    }
  }, [navigate, token]);

  // Clear signup success on unmount
  useEffect(() => {
    return () => {
      dispatch(clearSignupSuccess());
    };
  }, [dispatch]);

  // Toggle between login / signup
  const toggleMode = () => {
    setIsSignup(!isSignup);
    setFormErrors({});
    setFormData({
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
    });
    dispatch(clearSignupSuccess());
  };

  // Basic form validation
  const validateForm = () => {
    let errors = {};

    if (!formData.name) {
      errors.name = 'Company name is required';
    }

    if (!formData.password) {
      errors.password = 'Password is required';
    }

    if (isSignup) {
      if (!formData.email) {
        errors.email = 'Email is required';
      } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        errors.email = 'Invalid email address';
      }
      if (formData.password !== formData.confirmPassword) {
        errors.confirmPassword = "Passwords don't match";
      }
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Handle field changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormErrors({ ...formErrors, [e.target.name]: '' });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const action = isSignup ? signup : login;
      await dispatch(action(formData)).unwrap();
    } catch (err) {
      console.error('Authentication error:', err);
    }
  };

  // Toggle password visibility
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Add this with your other refs/state
  const companyNameRef = React.useRef();

  return (
    <AuthContainer container>
      {/* LEFT PANEL */}
      <Grid item xs={12} md={6}>
        <LeftPanel>
          <Overlay>
            <BrandingBox>
              <Box
                component="img"
                src={logo}
                alt="Intratalent AI"
                sx={{ width: '150px', mb: 2 }}
              />
              <Typography variant="h5" sx={{ fontWeight: 700 }}>
                intratalent ai
              </Typography>
            </BrandingBox>
          </Overlay>
        </LeftPanel>
      </Grid>

      {/* RIGHT PANEL */}
      <RightPanel item xs={12} md={6}>
        <FormWrapper>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
              marginBottom: 2,
              textAlign: 'center',
              color: '#1A365D',
            }}
          >
            {isSignup ? 'Sign Up' : 'Login'}
          </Typography>

          {error && (
            <Alert severity="error" sx={{ marginBottom: 2 }}>
              {error}
            </Alert>
          )}

          {signupSuccess && (
            <Alert severity="success" sx={{ marginBottom: 2 }}>
              {signupMessage}
            </Alert>
          )}

          {/* Add Google Sign-In Button */}
          <GoogleAuthButton 
            isSignup={isSignup} 
            companyNameRef={companyNameRef}
            setIsGoogleSignIn={setIsGoogleSignIn}
          />

          {isSignup && (
            <Typography
              variant="body2"
              sx={{
                textAlign: 'center',
                mt: 1,
                mb: 2,
                color: '#666',
                fontSize: '0.875rem',
                fontStyle: 'italic',
              }}
            >
              By signing up with Google, you enable access for all members of your Google Workspace domain
            </Typography>
          )}

          {!isSignup && (
            <Typography
              variant="body2"
              sx={{
                textAlign: 'center',
                mt: 1,
                mb: 2,
                color: '#666',
                fontSize: '0.875rem',
                fontStyle: 'italic',
              }}
            >
              Sign in with a Google Workspace account
            </Typography>
          )}

          <div style={{ textAlign: 'center', margin: '1rem 0' }}>
            <span>or</span>
          </div>

          <form onSubmit={handleSubmit}>
            <StyledTextField
              fullWidth
              label="Company Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              error={!!formErrors.name}
              helperText={formErrors.name}
              variant="outlined"
              sx={{ textTransform: 'none' }}
            />

            {/* Email (only for Signup) */}
            {isSignup && (
              <StyledTextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                error={!!formErrors.email}
                helperText={formErrors.email}
                variant="outlined"
              />
            )}

            {/* Password */}
            <StyledTextField
              fullWidth
              label="Password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={formData.password}
              onChange={handleChange}
              error={!!formErrors.password}
              helperText={formErrors.password}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {/* Confirm Password (only for Signup) */}
            {isSignup && (
              <StyledTextField
                fullWidth
                label="Confirm Password"
                name="confirmPassword"
                type="password"
                value={formData.confirmPassword}
                onChange={handleChange}
                error={!!formErrors.confirmPassword}
                helperText={formErrors.confirmPassword}
                variant="outlined"
              />
            )}

            <SubmitButton type="submit" fullWidth variant="contained" disabled={loading}>
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : isSignup ? (
                'Sign Up'
              ) : (
                'Log In'
              )}
            </SubmitButton>
          </form>

          <Box sx={{ textAlign: 'center', mt: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <AuthLinkText variant="body2">
              {isSignup ? 'Already have an account?' : "Don't have an account?"}
            </AuthLinkText>
            <AuthLinkButton onClick={toggleMode}>
              {isSignup ? 'Log In' : 'Sign Up'}
            </AuthLinkButton>
          </Box>
        </FormWrapper>
      </RightPanel>
    </AuthContainer>
  );
};

export default AuthPage;