import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { googleLogin, googleWorkspaceSignup } from '../../services/authSlice';
import { Button } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';

const GoogleAuthButton = ({ isSignup, companyNameRef, setIsGoogleSignIn }) => {
  const dispatch = useDispatch();
  const [googleLoaded, setGoogleLoaded] = useState(false);

  const handleGoogleAuth = async (response) => {
    const token = response.credential;
    
    if (isSignup) {
      // For signup, we'll get the company name from the token on the backend
      dispatch(googleWorkspaceSignup({ token }));
    } else {
      dispatch(googleLogin(token));
    }
  };

  React.useEffect(() => {
    // Load Google Sign-In script
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    script.onload = () => setGoogleLoaded(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  React.useEffect(() => {
    if (googleLoaded && window.google) {
      // Initialize Google Sign-In
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleGoogleAuth,
      });

      // Render Google Button
      window.google.accounts.id.renderButton(
        document.getElementById('googleButton'),
        { 
          type: 'standard',
          theme: 'outline',
          size: 'large',
          text: isSignup ? 'signup_with' : 'signin_with',
          width: '280',
          height: '40'
        }
      );
    }
  }, [googleLoaded, isSignup]);

  return (
    <div 
    id="googleButton" 
    style={{ 
        display: 'flex',
        justifyContent: 'center',
        minHeight: '40px',
        marginTop: '1rem', 
        marginBottom: '1rem',
        backgroundColor: 'white'
    }}
    ></div>
  );
};

export default GoogleAuthButton; 