import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from './api';

export const login = createAsyncThunk(
  'auth/login',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await api.login(credentials);
      if (!response.token) {
        throw new Error('No token received');
      }
      localStorage.setItem('token', response.token);
      localStorage.setItem('companyName', credentials.name);
      return {
        token: response.token,
        companyName: credentials.name,
        success: true
      };
    } catch (error) {
      return rejectWithValue(error.response?.data?.error || 'Login failed');
    }
  }
);

export const signup = createAsyncThunk(
  'auth/signup',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await api.signup(credentials);
      if (!response.success) {
        throw new Error('Signup failed');
      }
      return {
        message: response.message,
        name: credentials.name,
        email: credentials.email
      };
    } catch (error) {
      return rejectWithValue(error.response?.data?.error || 'Signup failed');
    }
  }
);

export const verifyEmail = createAsyncThunk(
  'auth/verifyEmail',
  async (token, { rejectWithValue }) => {
    try {
      const response = await api.verifyEmail(token);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error || 'Verification failed');
    }
  }
);

export const googleWorkspaceSignup = createAsyncThunk(
  'auth/googleWorkspaceSignup',
  async ({ token, companyName }, { rejectWithValue }) => {
    try {
      const response = await api.googleWorkspaceSignup(token, companyName);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error || 'Signup failed');
    }
  }
);

export const googleLogin = createAsyncThunk(
  'auth/googleLogin',
  async (token, { rejectWithValue }) => {
    try {
      const response = await api.googleLogin(token);
      if (!response.token) {
        throw new Error('No token received');
      }
      localStorage.setItem('token', response.token);
      localStorage.setItem('companyName', response.company.name);
      return {
        token: response.token,
        companyName: response.company.name,
        success: true
      };
    } catch (error) {
      return rejectWithValue(error.response?.data?.error || 'Login failed');
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: localStorage.getItem('token'),
    companyName: localStorage.getItem('companyName'),
    isAuthenticated: !!localStorage.getItem('token'),
    verificationStatus: null,
    signupSuccess: false,
    signupMessage: null,
    loading: false,
    error: null,
  },
  reducers: {
    logout: (state) => {
      localStorage.removeItem('token');
      localStorage.removeItem('companyName');
      state.token = null;
      state.companyName = null;
      state.isAuthenticated = false;
      state.error = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearSignupSuccess: (state) => {
      state.signupSuccess = false;
      state.signupMessage = null;
    }
  },
  extraReducers: (builder) => {
    builder
      // Login cases
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload.token;
        state.companyName = action.payload.companyName;
        state.isAuthenticated = true;
        state.error = null;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.isAuthenticated = false;
      })
      // Signup cases
      .addCase(signup.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.signupSuccess = false;
        state.signupMessage = null;
      })
      .addCase(signup.fulfilled, (state, action) => {
        state.loading = false;
        state.signupSuccess = true;
        state.signupMessage = action.payload.message;
        state.error = null;
      })
      .addCase(signup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.signupSuccess = false;
        state.signupMessage = null;
      })
      // Email verification cases
      .addCase(verifyEmail.pending, (state) => {
        state.verificationStatus = 'pending';
      })
      .addCase(verifyEmail.fulfilled, (state) => {
        state.verificationStatus = 'success';
      })
      .addCase(verifyEmail.rejected, (state) => {
        state.verificationStatus = 'failed';
      })
      // Google Workspace signup cases
      .addCase(googleWorkspaceSignup.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.signupSuccess = false;
        state.signupMessage = null;
      })
      .addCase(googleWorkspaceSignup.fulfilled, (state, action) => {
        state.loading = false;
        state.signupSuccess = true;
        state.signupMessage = 'Google Workspace company created successfully';
        state.error = null;
      })
      .addCase(googleWorkspaceSignup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.signupSuccess = false;
        state.signupMessage = null;
      })
      
      // Google login cases
      .addCase(googleLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(googleLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload.token;
        state.companyName = action.payload.companyName;
        state.isAuthenticated = true;
        state.error = null;
      })
      .addCase(googleLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.isAuthenticated = false;
      });
  }
});

export const { logout, clearError, clearSignupSuccess } = authSlice.actions;
export default authSlice.reducer;