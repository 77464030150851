import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from './api';

// Async thunk for creating link token
export const createLinkToken = createAsyncThunk(
  'ats/createLinkToken',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.createAtsLinkToken();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error || 'Failed to create link token');
    }
  }
);

export const exchangeToken = createAsyncThunk(
  'ats/exchangeToken',
  async (public_token, { rejectWithValue }) => {
    try {
      console.log('exchangeToken called');
      console.log('public_token:', public_token);
    
      const response = await api.exchangeAtsToken(public_token);
      return response;
    } catch (error) {
      console.log('error:', error);
      return rejectWithValue(error.response?.data?.error || 'Failed to exchange token');
    }
  }
);

export const syncAtsData = createAsyncThunk(
  'ats/syncData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.syncAtsData();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error || 'Failed to sync ATS data');
    }
  }
);

const atsSlice = createSlice({
  name: 'ats',
  initialState: {
    linkToken: null,
    loading: false,
    error: null,
    integrated: false,
    syncing: false,
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    clearLinkToken: (state) => {
      state.linkToken = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createLinkToken.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createLinkToken.fulfilled, (state, action) => {
        state.loading = false;
        state.linkToken = action.payload.link_token;
      })
      .addCase(createLinkToken.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to create link token';
      })
      .addCase(exchangeToken.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(exchangeToken.fulfilled, (state) => {
        state.loading = false;
        state.integrated = true;
      })
      .addCase(exchangeToken.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(syncAtsData.pending, (state) => {
        state.syncing = true;
        state.error = null;
      })
      .addCase(syncAtsData.fulfilled, (state) => {
        state.syncing = false;
      })
      .addCase(syncAtsData.rejected, (state, action) => {
        state.syncing = false;
        state.error = action.payload;
      });
  },
});

export const { clearError, clearLinkToken } = atsSlice.actions;
export default atsSlice.reducer; 